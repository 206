import(/* webpackMode: "eager", webpackExports: ["FooterForm"] */ "/vercel/path0/components/layout/footer/form/FooterForm.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Nav"] */ "/vercel/path0/components/layout/navigation/Nav.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AnimatedIcon"] */ "/vercel/path0/components/lib/atoms/animated-icon/AnimatedIcon.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/lib/atoms/breadcrumbs/Breadcrumbs.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Looper"] */ "/vercel/path0/components/lib/atoms/looper/Looper.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/lib/atoms/typography/Typography.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["VideoBlock"] */ "/vercel/path0/components/lib/content-sections/video-block/VideoBlock.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CollapsableList"] */ "/vercel/path0/components/lib/molecules/collapsable-list/CollapsableList.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ImagesSlideshow"] */ "/vercel/path0/components/lib/molecules/images-slideshow/ImagesSlideshow.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["BigNumbers"] */ "/vercel/path0/components/lib/sections/big-numbers/BigNumbers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["BurgerSection"] */ "/vercel/path0/components/lib/sections/burger/BurgerSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Carousel"] */ "/vercel/path0/components/lib/sections/carousel/components/Carousel.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/lib/sections/chess/cards/Texts.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/lib/sections/default-full-page-text/DefaultFullPageText.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["EventsListSection"] */ "/vercel/path0/components/lib/sections/events-list/EventsListSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HeaderHomepageSection"] */ "/vercel/path0/components/lib/sections/header-homepage/HeaderHomepageSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HorizontalAccordionCard"] */ "/vercel/path0/components/lib/sections/horizontal-accordion/card/HorizontalAccordionCard.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HorizontalStepsSection"] */ "/vercel/path0/components/lib/sections/horizontal-steps/HorizontalStepsSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MountainsSection"] */ "/vercel/path0/components/lib/sections/mountains/MountainsSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PartnerFlexiblySection"] */ "/vercel/path0/components/lib/sections/partner-flexibly/PartnerFlexiblySection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SuccessStoriesCard"] */ "/vercel/path0/components/lib/sections/success-stories/cards/SuccessStoriesCard.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/lib/sections/testimonials/TestimonialsSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TimelineSection"] */ "/vercel/path0/components/lib/sections/timeline/TimelineSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["VlGroupSection"] */ "/vercel/path0/components/lib/sections/vl-group/VlGroupSection.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/example_image.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/burger/layer_0.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/burger/layer_1.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/burger/layer_2.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/footer/footer_bottom_waves.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/lines-in-background.png");
